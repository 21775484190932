<template>
  <div>
    <a-form-model
      ref="addAccountForm"
      :model="addAccountForm"
      :rules="addAccountRules"
    >
      <a-row class="mb-0">
        <a-col :span="14" class="pr-1">
          <a-form-model-item ref="name" :label="`${$t('common.agent')} ${$t('common.name')}`" prop="name">
            <a-input v-model="addAccountForm.name" :placeholder="`${$t('pre.eg')}Ivan Ivanov`" />
          </a-form-model-item>
        </a-col>
        <a-col :span="4">
          <a-form-model-item ref="sex" :label="`${$t('common.sex')}`" prop="sex">
            <a-radio-group v-model="addAccountForm.sex" button-style="solid">
              <a-radio-button value="male">
                <img src="/resources/images/avatars/user-agent-m.png" width="20" />
              </a-radio-button>
              <a-radio-button value="female">
                <img src="/resources/images/avatars/user-agent-w.png" width="20" />
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
        </a-col>
        <a-col :span="6" class="pl-1">
          <a-form-model-item ref="currency_id" :label="`${$t('common.currency')}`" prop="currency_id">
            <a-select v-model="addAccountForm.currency_id"
                      :placeholder="$t('placeholders.selectCurrency')"
                      :disabled="!activeCurrencies"
            >
              <a-select-option v-for="currency in activeCurrencies" :key="currency.id" >
                {{ currency.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item ref="projects" :label="`${$t('buttons.btnBind')}${$t('pre.to')}${$t('common.projects')}`" prop="projects">
            <a-select
              mode="multiple"
              :placeholder="$t('placeholders.selectProjects')"
              v-model="addAccountForm.project_ids"
              style="width: 100%"
              @change="handleProjectChange"
            >
              <a-select-option v-for="project in activeProjectList" :key="project.id" :value="project.id">
                {{ project.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-tabs default-active-key="1" class="mb-4">
        <a-tab-pane key="1" :forceRender="true">
          <span slot="tab">
            <a-icon type="credit-card" /> BUY
          </span>
          <a-row>
            <a-col :span="8" class="pr-1">
              <a-form-model-item ref="bch_comm" :label="$t('common.margin')" prop="bch_comm">
                <a-input-number
                  v-model="addAccountForm.bch_comm"
                  class="w-100"
                  :default-value="4"
                  :min="0"
                  :max="50"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="8" class="pl-1 pr-1">
              <a-form-model-item ref="min_amount_usd" prop="min_amount_usd">
                <a-input-number
                  v-model="addAccountForm.min_amount_usd"
                  class="w-100"
                  :default-value="500"
                  :step="0.01"
                  :min="1"
                  :max="9999"
                  :formatter="value => `${value}$`"
                  :parser="value => value.replace('$', '')"
                />
                <span slot="label"><a-icon type="vertical-align-bottom"/> {{ 'min. order sum' }}</span>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" class="pl-1">
              <a-form-model-item ref="trust_level_buy" prop="trust_level_buy">
                <a-input-number
                  v-model="addAccountForm.client_trust_level_buy"
                  class="w-100"
                  :default-value="0"
                  :step="1"
                  :min="0"
                  :max="100"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
                />
                <span slot="label"><a-icon type="file-protect"/> {{ 'Client trust level' }}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8" class="pr-1">
              <a-form-model-item ref="buy_limit" prop="buy_limit">
                <a-input-number
                  v-model="addAccountForm.buy_limit"
                  class="w-100"
                  :formatter="value => `${value}$`"
                  :parser="value => value.replace('$', '')"
                  disabled
                />
                <span slot="label">
                  <a-icon type="vertical-align-top"/> Buy Limit
                  <a v-if="accountInfo" href="javascript:" @click="showEditBuyLimitModal"><a-icon type="edit" /></a>
                </span>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" class="pl-1 pr-1">
              <a-form-model-item ref="buy_credit" prop="buy_credit">
                <a-input-number
                  v-model="addAccountForm.buy_credit"
                  class="w-100"
                  :default-value="5"
                  :min="0"
                  :max="9999"
                  :formatter="value => `${value}$`"
                  :parser="value => value.replace('$', '')"
                />
                <span slot="label"><a-icon type="vertical-align-top"/> Credit</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <div class="setting_options">
            <div>
              <a-checkbox v-model="addAccountForm.allow_sell_buy_limit" name="allow_sell_buy_limit">
                <i class="fe fe-trending-down font-size-14 text-primary" /> {{ 'Allow to sell BuyLimit' }}
              </a-checkbox>
            </div>
            <div class="mt-1">
              <a-checkbox v-model="addAccountForm.hold_debt_buy_order_expired">
                <i class="fe fe-dollar-sign font-size-14 text-primary" /> {{ 'Hold debt when order expired' }}
              </a-checkbox>
            </div>
            <div class="mt-1">
              <a-checkbox v-model="addAccountForm.choose_requisite_on_accepting_buy_order">
                <i class="fe fe-layers font-size-14 text-primary" /> {{ 'Choose requisites when accepting an order' }}
              </a-checkbox>
            </div>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" :forceRender="true">
          <span slot="tab">
            <a-icon type="credit-card" /> SELL
          </span>
          <a-row>
            <a-col :span="8" class="pr-1">
              <a-form-model-item ref="min_buy_usd" prop="min_buy_usd">
                <a-input-number
                  v-model="addAccountForm.min_buy_usd"
                  class="w-100"
                  :default-value="1"
                  :step="0.01"
                  :min="1"
                  :max="9999"
                  :formatter="value => `${value}$`"
                  :parser="value => value.replace('$', '')"
                />
                <span slot="label"><a-icon type="vertical-align-bottom"/> {{ 'min. order sum' }}</span>
              </a-form-model-item>
            </a-col>
            <a-col :span="8" class="pl-1">
              <a-form-model-item ref="trust_level_sell" prop="trust_level_sell">
                <a-input-number
                  v-model="addAccountForm.client_trust_level_sell"
                  class="w-100"
                  :default-value="0"
                  :step="1"
                  :min="0"
                  :max="100"
                  :formatter="value => `${value}%`"
                  :parser="value => value.replace('%', '')"
                />
                <span slot="label"><a-icon type="file-protect"/> {{ 'Client trust level' }}</span>
              </a-form-model-item>
            </a-col>
          </a-row>
          <div class="setting_options">
            <a-checkbox v-model="addAccountForm.auto_confirm_sell_orders">
              <i class="fe fe-check-square font-size-14 text-primary" /> {{ 'Order auto-confirmation' }}
            </a-checkbox>
          </div>
        </a-tab-pane>
        <a-tab-pane key="3" :forceRender="true">
          <span slot="tab">
            <a-icon type="trophy" />
            {{ $t('common.rewards') }}
          </span>
          <a-row>
            <a-col :span="12" class="pr-1">
              <a-form-model-item ref="reward_type_id" :label="`${$t('common.rewards')} ${$t('common.plan')}${$t('pre.for')}${$t('common.purchases')}`" prop="reward_type_id">
                <a-select v-model="addAccountForm.settings.reward_type_id"
                          :placeholder="$t('placeholders.selectPlan')"
                          :disabled="!activeCurrencies"
                          @change="handleRewardPlanChange"
                >
                  <a-select-option :value="0" >
                    {{ $t('common.personal') }}
                  </a-select-option>
                  <a-select-option v-for="rewardPlan in accountRewardPlans" :key="rewardPlan.id" >
                    {{ rewardPlan.name }} ({{ `${rewardPlan.fixed_amount}$ + ${rewardPlan.percent_amount}%` }})
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <span v-if="addAccountForm.settings.reward_type_id === 0">
              <a-col :span="6" class="pl-1 pr-1">
                <a-form-model-item ref="fixed_amount" :label="$t('labels.fixedPart')" prop="fixed_amount">
                  <a-input-number
                    v-model="addAccountForm.reward_personal.fixed_amount"
                    class="w-100"
                    :default-value="500"
                    :min="0"
                    :max="9999"
                    :step="0.01"
                    :formatter="value => `$ ${value}`"
                    :parser="value => value.replace('$ ', '').replace('$', '')"
                  />
                </a-form-model-item>
              </a-col>
              <a-col :span="6" class="pl-1">
                <a-form-model-item ref="percent_amount" :label="$t('labels.percentPaymentAmount')" prop="percent_amount">
                  <a-input-number
                    v-model="addAccountForm.reward_personal.percent_amount"
                    class="w-100"
                    :default-value="500"
                    :min="0"
                    :max="9999"
                    :step="0.01"
                    :formatter="value => `${value}`"
                    :parser="value => value.replace('$', '')"
                  />
                </a-form-model-item>
              </a-col>
            </span>
            <span v-else>
              <a-col :span="6" class="pl-1 pr-1">
                <a-form-model-item ref="plan_fixed_amount" :label="$t('labels.fixedPart')" prop="plan_fixed_amount">
                  <span>$ {{ currentPlanFixed }}</span>
                </a-form-model-item>
              </a-col>
              <a-col :span="6" class="pl-1">
                <a-form-model-item ref="plan_percent_amount" :label="$t('labels.percentPaymentAmount')" prop="plan_percent_amount">
                  <span>{{ currentPlanPercent }}</span>
                </a-form-model-item>
              </a-col>
            </span>
          </a-row>
        </a-tab-pane>
        <a-tab-pane key="4" :forceRender="true">
          <span slot="tab">
            <a-icon type="contacts" />
            {{ $t('common.contact') }}
          </span>
          <a-row class="pb-0">
            <a-col :span="8" class="pr-1 pb-0">
              <a-form-model-item ref="email" label="Email" prop="email">
                <a-input v-model="addAccountForm.email" placeholder="" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8" class="pl-1 pr-1 pb-0">
              <a-form-model-item ref="phone" :label="$t('common.phone')" prop="phone">
                <a-input v-model="addAccountForm.phone" placeholder="" />
              </a-form-model-item>
            </a-col>
            <a-col :span="8" class="pl-1 pb-0">
              <a-form-model-item ref="telegram" label="Telegram" prop="telegram">
                <a-input v-model="addAccountForm.telegram" placeholder="" />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-tab-pane>
        <a-tab-pane v-if="accountInfo" key="5" :forceRender="true">
          <span slot="tab">
            <a-icon type="team" />
            Interconnects
          </span>
          <div>
            <agent-interconnect-list :agent-id="accountInfo.id" />
          </div>
        </a-tab-pane>
      </a-tabs>
      <hr/>
    </a-form-model>
    <a-row class="mt-2">
      <a-col :span="14">
        <div>
          <a-checkbox v-model="addAccountForm.allow_withdraw" name="allow_withdraw">
            <i class="fe fe-dollar-sign font-size-14 text-primary" /> {{ 'Allow to withdraw' }}
          </a-checkbox>
        </div>
        <div class="mt-1">
          <a-checkbox v-model="addAccountForm.hc" name="hc">
            <i class="fe fe-message-circle font-size-14 text-primary" /> {{ `Allow to chat client` }}
          </a-checkbox>
        </div>
      </a-col>
      <a-col :span="10" class="text-right">
        <div class="text-right mt-4">
          <a-button type="primary" @click="onSubmit" :loading="submitLoading">
            {{ accountInfo ? $t('buttons.btnSave') : $t('buttons.btnAdd') }}
          </a-button>
          <a-button style="margin-left: 1rem;" @click="closeModal">
            {{ $t('buttons.btnCancel') }}
          </a-button>
        </div>
      </a-col>
    </a-row>
    <a-modal v-model="editBuyLimitModal"
             :destroyOnClose="true"
             :title="'Change agent buyLimit'"
             :footer="null"
             class="edit-buy-limit-modal"
             width="440px"
    >
      <edit-buy-limit-modal
        :available="addAccountForm.buy_limit"
        :balance="accountInfo?.balance"
        :agent-id="accountInfo?.id"
        @closeModalFunction="closeEditBuyLimitModal"
        @submitBuyModalFunction="editBuyLimitSubmit"
        @submitSellModalFunction="editBuyLimitSubmit"
        @submitSellAndWriteOffModalFunction="editBuyLimitSubmit" />
    </a-modal>
  </div>
</template>
<script>
import apiClient from '@/services/axios'
import { mapGetters } from 'vuex'
import editBuyLimitModal from '@/views/accounts/buyLimit/editBuyLimitModal.vue'
import AgentInterconnectList from '@/views/accounts/interconnects/AgentInterconnectList.vue'

export default {
  name: 'addAccount',
  components: {
    editBuyLimitModal,
    AgentInterconnectList,
  },
  props: ['accountInfo'],
  computed: {
    ...mapGetters(['activeCurrencies', 'activeProjectList', 'accountRewardPlans']),
    defaultPlan() {
      return this.accountRewardPlans.find(el => el.is_default === true)
    },
    selectedPlan() {
      return this.accountRewardPlans.find(el => el.id === this.addAccountForm.settings.reward_type_id)
    },
    defaultPlanValues() {
      // const defaultPlan = this.accountRewardPlans.find(el => el.is_default === true)
      const fixed = this.defaultPlan ? this.defaultPlan.fixed_amount : 0
      const percent = this.defaultPlan ? this.defaultPlan.percent_amount : 0
      return { fixed: fixed, percent: percent, id: this.defaultPlan?.id }
    },
  },
  watch: {
    accountRewardPlans(ov, nv) {
      console.log('log', ov, nv)
      this.addAccountForm.reward_personal.fixed_amount = this.defaultPlanValues.fixed
      this.addAccountForm.reward_personal.percent_amount_amount = this.defaultPlanValues.percent
      // this.defaultRewardTypeId = this.defaultPlanValues.id
    },
    defaultPlanValues(ov, nv) {
      console.log('defaultPlanValues changed')
      this.defaultRewardTypeId = 2
    },
  },
  data() {
    return {
      submitLoading: false,
      defaultRewardFixed: 0,
      defaultRewardPercent: 0,
      defaultRewardTypeId: 0,
      currentPlanFixed: 0,
      currentPlanPercent: 0,
      addAccountForm: {
        name: this.accountInfo && this.accountInfo?.name ? this.accountInfo.name : '',
        sex: this.accountInfo && this.accountInfo?.sex ? this.accountInfo.sex : 'male',
        currency_id: this.accountInfo ? this.accountInfo.currency_id : 1,
        is_coward: this.accountInfo ? this.accountInfo.is_coward : 0,
        telegram: this.accountInfo?.contact ? this.accountInfo.contact.telegram : '',
        email: this.accountInfo?.contact ? this.accountInfo.contact.email : '',
        phone: this.accountInfo?.contact ? this.accountInfo.contact.phone : '',
        bch_comm: this.accountInfo ? this.accountInfo.bch_comm : 0,
        buy_credit: this.accountInfo ? this.accountInfo.settings.common.payments.buy_credit : 0,
        buy_limit: this.accountInfo ? this.accountInfo.settings.common.payments.buy_limit.toFixed(2) : 0,
        allow_sell_buy_limit: this.accountInfo ? this.accountInfo.settings.common.payments.buy_limit_allow_to_sell : true,
        allow_withdraw: this.accountInfo ? this.accountInfo.settings.common.payouts.enabled : false,
        auto_confirm_sell_orders: this.accountInfo ? this.accountInfo.settings.common.payments.auto_confirm_sell_orders.enabled : false,
        hold_debt_buy_order_expired: this.accountInfo ? this.accountInfo.settings.common.payments.hold_debt_buy_order_expired.enabled : false,
        choose_requisite_on_accepting_buy_order: this.accountInfo ? this.accountInfo.settings.common.payments.choose_requisite_on_accepting_buy_order.enabled : false,
        max_debt: this.accountInfo ? this.accountInfo.max_debt : 500,
        max_debt_inc: this.accountInfo ? this.accountInfo.max_debt_inc : true,
        min_amount_usd: this.accountInfo ? this.accountInfo.min_amount_usd : 1,
        min_buy_usd: this.accountInfo ? this.accountInfo.min_buy_usd : 1,
        hc: this.accountInfo ? this.accountInfo.hc : true,
        project_ids: this.accountInfo ? this.accountInfo.project_ids : [],
        settings: {
          reward_type_id: this.accountInfo ? this.accountInfo.settings.reward_type_id : this.defaultRewardTypeId,
        },
        reward_personal: {
          fixed_amount: this.accountInfo ? this.accountInfo.reward_personal.fixed_amount : this.defaultRewardFixed,
          percent_amount: this.accountInfo ? this.accountInfo.reward_personal.percent_amount : this.defaultRewardPercent,
        },
        client_trust_level_buy: this.accountInfo ? this.accountInfo.settings.common.trust.client_buy_level : 0,
        client_trust_level_sell: this.accountInfo ? this.accountInfo.settings.common.trust.client_sell_level : 0,
      },
      addAccountRules: {
        name: [{ required: true, message: `${this.$t('validations.agentNameRequired')}`, trigger: 'change' }],
        currency_id: [{ required: true, message: `${this.$t('validations.currencyRequired')}`, trigger: 'change' }],
      },
      editBuyLimitModal: false,
    }
  },
  mounted () {
    this.defaultRewardTypeId = this.defaultPlanValues.id
    this.currentPlanFixed = !this.accountInfo || !this.selectedPlan ? this.defaultPlanValues.fixed : this.selectedPlan.fixed_amount
    this.currentPlanPercent = !this.accountInfo || !this.selectedPlan ? this.defaultPlanValues.percent : this.selectedPlan.percent_amount
    if (!this.accountInfo) {
      this.addAccountForm.settings.reward_type_id = this.defaultRewardTypeId
      this.addAccountForm.reward_personal.fixed_amount = this.defaultPlanValues.fixed
      this.addAccountForm.reward_personal.percent_amount = this.defaultPlanValues.percent
    }
    console.log('def rew type id', this.defaultRewardTypeId)
  },
  methods: {
    closeModal() {
      this.$emit('closeModalFunction')
    },
    submitModal(record, edit = false) {
      this.$emit('submitModalFunction', record, edit)
    },
    handleProjectChange(selected) {
      console.log(selected)
      console.log(this.defaultPlanValues)
    },
    handleRewardPlanChange(selected) {
      if (selected > 0) {
        this.currentPlanFixed = this.selectedPlan.fixed_amount
        this.currentPlanPercent = this.selectedPlan.percent_amount
      }
      console.log('reward plan changed', selected)
    },
    showEditBuyLimitModal() {
      this.editBuyLimitModal = true
    },
    closeEditBuyLimitModal() {
      this.editBuyLimitModal = false
    },
    editBuyLimitSubmit(record, amount) {
      // console.log('actual value: ' + record.value + ' , amount: ' + amount)
      this.addAccountForm.buy_limit = record.value
      this.closeEditBuyLimitModal()
      this.$emit('refreshListFunction')
    },
    onSubmit() {
      this.$refs.addAccountForm.validate(valid => {
        if (valid) {
          this.accountInfo ? this.editAccountSubmit() : this.addAccountSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    resetForm() {
      this.$refs.addAccountForm.resetFields()
    },
    addAccountSubmit() {
      this.submitLoading = true
      const url = '/admin/accounts'
      return apiClient.post(url, this.addAccountForm).then((response) => {
        this.$notification.success({
          message: `${this.$t('messages.newAgentCreated')}`,
          description: response.data.data.name,
        })
        this.submitLoading = false
        this.submitModal(response.data.data)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: `${this.$t('messages.errorCreatingAgent')}`,
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    editAccountSubmit() {
      this.submitLoading = true
      const url = '/admin/accounts/' + this.accountInfo.id
      return apiClient.patch(url, this.addAccountForm).then((response) => {
        this.$notification.success({
          message: `${this.$t('messages.agentSettingsUpdated')}`,
          description: response.data.data.name,
        })
        this.submitLoading = false
        this.submitModal(response.data.data, true)
      }).catch(error => {
        console.log(error)
        this.$notification.error({
          message: `${this.$t('messages.errorUpdatingAgent')}`,
          description: error.message,
        })
        this.submitLoading = false
      })
    },
    randomString(length) {
      let result = ''
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
      const charactersLength = characters.length
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
      }
      return result
    },
  },
}
</script>

<style scoped>
</style>
